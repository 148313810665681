import React, { useLayoutEffect } from 'react'
import Flickity from 'react-flickity-component'
import 'flickity/dist/flickity.min.css';


const CustomDevelopmentProcess = (props) => {
    let flkty = null;
    useLayoutEffect(() => {
        const updateDimensions = () => {
            var screenWidth = window.innerWidth
            var stopIndex = 0
            if (screenWidth >= 920) {
                stopIndex = 3
            } else if (screenWidth <= 919 && screenWidth >= 768) {
                stopIndex = 2
            } else {
                stopIndex = -1
            }
            var i = 0;
            flkty.on("select", () => {
                if (document.querySelector('.rec-process-carousel') !== null) {
                    var preBtn = document.querySelector('.rec-process-carousel .previous');
                    var nextBtn = document.querySelector('.rec-process-carousel .next');
                    var slideNumber = flkty.selectedIndex + 1;
                    var slideStop = flkty.slides.length - stopIndex
                    if (slideNumber === 1) {
                        i = 0;
                    } else {
                        i++;
                    }
                    if (slideNumber > slideStop) {
                        nextBtn.disabled = true;
                        preBtn.disabled = false;
                        nextBtn.classList.add('pre-next-btn-disabled');
                        preBtn.classList.remove('pre-next-btn-disabled');
                        preBtn.classList.remove('s-prebtn-pos');
                        nextBtn.classList.remove('s-nextbtn-pos');
                    } else if (slideStop === 0 || slideStop === -1) {
                        preBtn.disabled = false;
                        nextBtn.disabled = false;
                        nextBtn.classList.remove('pre-next-btn-disabled');
                        preBtn.classList.remove('pre-next-btn-disabled');
                    } else {
                        if (i > slideStop) {
                            nextBtn.disabled = true;
                            preBtn.disabled = false;
                            nextBtn.classList.add('pre-next-btn-disabled');
                            preBtn.classList.remove('pre-next-btn-disabled');
                        } else {
                            nextBtn.disabled = false;
                            preBtn.disabled = true;
                            nextBtn.classList.remove('pre-next-btn-disabled');
                            preBtn.classList.add('pre-next-btn-disabled');
                        }
                        preBtn.classList.add('s-prebtn-pos');
                        nextBtn.classList.add('s-nextbtn-pos');
                    }
                }
            });
        }

        window.addEventListener('resize', updateDimensions);
        updateDimensions();
        return () => window.removeEventListener('resize', updateDimensions);

    }, [flkty]);

    const flickityOptions = {
        prevNextButtons: true,
        pageDots: false,
        cellAlign: 'left',
        contain: true,
        imagesLoaded: true,
        percentPosition: false,
        selectedAttraction: 0.01,
        friction: 0.15
    }

    const data = props.processList


    return (
        <div className="s-process-slider s-process-inviewport">
            <div className="s-process-slider__container s-slider-main">
                <div className="re-shadows re-shadow-left"></div>
                <div className="re-shadows re-shadow-right"></div>
                <Flickity
                    flickityRef={c => flkty = c}
                    className={'rec-process-carousel'}
                    elementType={'div'}
                    options={flickityOptions} >
                    {data.map(({ process: list }, i) =>
                        <div key={i} className="rec-process-cell">
                            <div className="rec-process-inn">
                                <div className="pb-5 2md:pb-6 lg:pb-6 w-42">
                                    <img
                                        src={list.image.publicURL}
                                        className="w-42 h-42"
                                        alt={list.alt}
                                    ></img>
                                </div>
                                <div className={`border-t-2 relative ${i === 0 ? "d-work-dot" : "d-work-dots"} border-cyan mb-6-3 lg:mb-4-3`}></div>
                                <div className="ml-4-1">
                                    <h4 className="pv-2-2 lg:pr-12 pb-1-2 text-h6 lg:text-h4 font-poppings-bold text-term-primary">
                                        {list.name}
                                    </h4>
                                    <p className="pr-5 lg:pr-6-6 xl:pr-49 text-p4 lg:text-p2 font-worksans-normal text-term-primary text-opacity-60  opacity-60">
                                        {list.description}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </Flickity>
            </div>
        </div>
    )
}


export default CustomDevelopmentProcess;