import React from "react"
import { graphql, Link } from "gatsby";
import MarkDown from "react-markdown";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

//  component
import Layout from "../components/layout";
import Seo from "../components/seo";
import TestimonialsText from "../components/Home/testimonials-text";
import LiveChat from "../components/Home/livechat";
import CustomDevelopmentProcess from "../components/custom-development-process";

//image
import rightarrow from "../images/right-arrow.svg";
import rightArrowWhite from "../images/uploads/decodeup-right-sign-white.svg"
import addcs1 from "../../images/uploads/decodeup-additional-case-study-cigar-near-me.png"
import addcs2 from "../../images/uploads/decodeup-additional-case-study-square-one-media.png"


const CustomWebApplications = (props) => {

  const { customWebData: servicesData, caseStudiesData: caseStudies } = props.data
  // const SEOdata = servicesData.frontmatter.seo_metadata

  const SEOdata = props.data.awardpageData.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = props.data.awardpageData.edges[0].node.frontmatter?.Schema
  const Hreflang = props.data.awardpageData.edges[0].node.frontmatter?.Hreflang


  return (
    <>
      <Layout>
        <Seo
          title={SEOdata ? SEOdata.seo_title : "Custom-web-applications"}
          description={SEOdata ? SEOdata.seo_description : ""}
          keywords={SEOdata ? SEOdata.seo_keyword : ""}
          image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
          single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
          currentPageUrlPath={props.location.pathname}
          image_format={SEOdata ? SEOdata.image_format : ""}
          image_height={SEOdata ? SEOdata.feature_image_height : ""}
          img_width={SEOdata ? SEOdata.img_width : ""}
          facebook_url={SEOdata ? SEOdata.facebook_url : ""}
          twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
          twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
          twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
          twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
          page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
          page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
          sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
          breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
          logo_schema={Schemadata ? Schemadata.logo_schema : ""}
          organization_schema={Schemadata ? Schemadata.organization_schema : ""}
          aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
          local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
          site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
          speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
          hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
          hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
          hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
          hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
        />

        {/* banner section */}
        <section className="py-50 d-website-design mt-60 lg:mt-20 bg-cyan relative">
          <div className="container mx-auto">
            <div className="xl:pl-1-11 flex flex-wrap items-center overflow-hidden">
              <div className="xl:mr-79 w-full sm:pr-3 md:pr-0 sm:w-1/2 xl:w-4/11 d-service-banner overflow-hidden">
                <GatsbyImage
                  image={getImage(
                    servicesData.frontmatter.featured_image.image
                  )}
                  alt={`${servicesData.frontmatter.featured_image.alt}`}
                />
              </div>
              <div className="pt-5 sm:pt-0 sm:pl-4 md:pl-6-6 xl:pl-0 xl:pr-50 sm:w-1/2 relative">
                <h6 className="mb-1-1 lg:mb-2 text-h6 font-poppings-normal uppercase text-celeste">
                  {servicesData.frontmatter.title}
                </h6>
                <h2 className="service-text text-rh2 sm:text-h3 lg:text-h2 font-poppings-bold text-white">
                  {servicesData.frontmatter.description}
                </h2>
              </div>
            </div>
          </div>
        </section>

        {/* introduction section */}
        <section className="pt-35 pb-50 lg:py-70 xl:pt-1-15 xl:pb-1-05">
          <div className="container mx-auto">
            <div className="xl:pl-1-11 ">
              <div className="pb-5 md:pb-0 lg:pr-65 xl:pr-0 2xl:pr-6-1 ">
                <h3 className="mb-1 lg:mb-6-1 sm:w-11/12 lg:w-auto text-rp1 md:text-h3 font-poppings-bold text-term-primary md:text-left">
                  {servicesData.frontmatter.introduction_title}
                </h3>
                <div
                  className="md:w-11/12 lg:w-auto text-p4 lg:text-p2 font-worksans-normal text-term-primary md:text-left cstm-web-service-intro-main-sec"
                  dangerouslySetInnerHTML={{ __html: servicesData.html }}
                ></div>
              </div>

              <ul className="md:mt-10 grid grid-flow-col grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 grid-rows-3-00 sm:grid-rows-70 lg:grid-rows-1 gap-4">
                {servicesData.frontmatter.sub_services.map(
                  (sub_service, index) => (
                    // <div key={index} className="grid grid-cols-10 gap-4 text-term-primary font-worksans-normal text-p4 lg:text-p2">
                    // 	<div className="col-span-2 mt-1 sm:mt-0 sm:pt-1-3 lg:pt-2">
                    // 		<img src={rightarrow} className="w-3 mt-0-2 md:w-3-2 lg:w-auto md:mt-0" alt="rightarrow"></img>
                    // 	</div>
                    // 	<span className="cstm-services-sub-info-text col-span-8 capitalize ">{sub_service}</span>
                    // </div>

                    <li
                      key={index}
                      className="pl-6 lg:pl-36 list-none text-term-primary font-worksans-normal text-p4 lg:text-p2 capitalize relative service-info-listing"
                    >
                      <span>{sub_service}</span>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        </section>

        {/* Benefits of a Custom Application */}

        <section className="d-web-development-reasons">
          <div className="bg-background-primary">
            <div className="container mx-auto">
              <div className="relative">
                <div className="pt-38 pb-67 lg:pb-1-21 relative flex flex-wrap">
                  <div className="w-full md:w-1/2 bg-cyan d-service--info">
                    <div className="pt-62 pb-6-6 pl-5-3 pr-43 1md:pr-70 md:pb-50 xl:pr-60 lg:pl-12 xl:pt-84 xl:pb-84 xl:pl-100 2xl:pl-1-12 2xl:pr-1-01 relative">
                      <h2 className="pb-3 md:pb-4 font-poppings-bold text-rh2 lg:text-h2 text-white">
                        {servicesData.frontmatter.need_new_site.heading}
                      </h2>
                      <p className="pr-6 font-worksans-normal text-p4 lg:text-p2 text-white">
                        If you answer "yes" to the following:
                      </p>

                      <ul className="pt-6 lg:pt-10">
                        {servicesData.frontmatter.need_new_site.points_left.map(
                          ({ point: data }, l) => (
                            <li
                              key={l}
                              className="pl-8 pb-6-6 relative decode-yesanswer-sec list-none"
                            >
                              <p className="font-worksans-normal text-p4 lg:text-p2 text-white opacity-60">
                                {data.description}
                              </p>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="pb-8 w-full md:w-1/2 bg-background-secondary">
                    <div className="pt-61 pb-6 md:pb-50 pl-5-3 pr-35 lg:pl-8 xl:pt-88 xl:pr-1-12 2xl:pr-1-30 xl:pb-54 xl:pl-63">
                      <p className="pb-6-6 pr-6 font-worksans-normal text-p4 lg:text-p2 text-white">
                        The longer answer?
                      </p>
                      <ul>
                        {servicesData.frontmatter.need_new_site.points_right.map(
                          ({ point: data }, r) => (
                            <li
                              key={r}
                              className="pl-8 pb-6-6 relative decode-noanswer-sec list-none"
                            >
                              <p className="font-worksans-normal text-p4 lg:text-p2 text-white opacity-60">
                                {data.description}
                              </p>
                            </li>
                          )
                        )}
                      </ul>
                      <div className="pt-4 ">
                        <p className="mb-5 font-poppings-bold text-rh3 lg:text-h4 text-cyan ">
                          If you answered yes to one or more of these questions,
                          it’s time to get a new, upgraded website!
                        </p>
                        <Link
                          className="py-3 pl-52 pr-54 inline-block font-poppings-bold text-center text-dark-blue text-h5 bg-celeste shadow-3 hover:shadow-none transition-all duration-300 ease-in-out delay-0"
                          to="/get-in-touch"
                        >
                          Connect with Us
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* how its work section */}
        <section className="py-35 lg:pt-78 lg:pb-61 relative bg-light-grey">
          <div className="container  relative  mx-auto">
            <div className="pb-6-1 lg:pb-12 2xl:px-10 w-full 2xl:w-3/4 mx-auto sm:text-center">
              <h3 className="pb-1-2 md:pb-5 lg:pb-6-6 xl:pb-6 text-rp1 md:text-h3 font-poppings-bold text-term-primary">
                How It Works
              </h3>
              <MarkDown
                className="sm:px-60 md:px-0 text-p4 lg:text-p2 font-worksans-normal text-term-primary work-text"
                children={servicesData.frontmatter.how_it_works.description}
              />
              <p className="relative text-term-primary">
                Our custom development process includes:
                <span className="inline-block rounded-full bg-cyan cursor-pointer relative text-white font-poppings-bold i-tooltip-main ">
                  i
                </span>
                <span className="p-2-2 z-10 absolute font-worksans-normal text-white i-tooltip-sec ">
                  Custom applications are just that: custom. Each project comes
                  with its own set of unique conditions, variables, obstacles,
                  and goals.
                </span>
              </p>
            </div>
            {/* services procedure slider */}
            <CustomDevelopmentProcess
              processList={servicesData.frontmatter.how_it_works.process_list}
            />
            <div className="pt-45 pb-5 text-center">
              <Link
                to="/our-process"
                className="py-3 pl-52 pr-54 inline-block font-poppings-bold text-center text-dark-blue text-h5 bg-celeste shadow-1 hover:shadow-none transition-all duration-300 ease-in-out delay-0"
              >
                See Detailed Process
              </Link>
            </div>
          </div>
        </section>

        {/* Development Analysis section */}

        <section className="pb-50 lg:pb-1-20 pt-50 lg:pt-79 ">
          <div className="container mx-auto">
            <h3 className="pb-35 xl:pb-51 text-rp1 md:text-h3 font-poppings-bold text-term-primary text-center">
              Development Analysis
            </h3>
            <div className="h-full flex flex-wrap">
              <div className="px-4-1 pt-36 w-full sm:w-1/2 text-center bg-cyan">
                <Link
                  // to={post.fields.slug}
                  to="/case-study/luxury-real-estatemedia-managements"
                  className="h-full flex items-center justify-between flex-col text-center addi-case-links"
                >
                  <p className="pb-2-2 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                    Luxury Real Estate Media Management System
                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <img
                      className="w-full h-full"
                      src={addcs2}
                      alt="Casestudy for Real Estate Media Management"
                    />
                  </div>
                </Link>
              </div>
              <div className="px-4-1 pt-36 w-full sm:w-1/2 text-center bg-background-case">

                <Link
                  to="/case-study/social-media-for-cigar-enthusiasts"
                  className="h-full flex items-center justify-between flex-col text-center addi-case-links"
                >
                  <p className="pb-2-2 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                    Social Media for Cigar Enthusiasts
                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <img
                      className="w-full h-full"
                      src={addcs1}
                      alt="Best Social Networking Platform"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/* client testimonial section */}

        <section>
          <div className="container mx-auto">
            <div className="pt-5 pb-53 pr-2 pl-5-3 lg:pl-60 lg:pr-60 xl:px-90 lg:pt-0 lg:pb-1-40 xl:pl-1-41 xl:pr-1-41 2xl:pl-1-86 2xl:pr-1-87">
              <TestimonialsText />
            </div>
          </div>
        </section>

        {/* schedule a free cunsultation chat section */}
        <LiveChat />
      </Layout>
    </>
  )
}


export default CustomWebApplications;

export const query = graphql`
query($related_CS_3 :[String!]!) {
	caseStudiesData: allMarkdownRemark(
		filter: { 
			frontmatter: {
				title: { in: $related_CS_3, ne: "dummy" } 
				templateKey: {eq: "single-case-study"},
			}
		}
		) {
			edges {
				node {
					id
					fields {
						slug
					}
					frontmatter {
						title
						short_details {
							bg_color_light
							bg_color_dark
						} 
					}
				}
			}
		}
	customWebData: markdownRemark(
		fileAbsolutePath: {regex: "/services/custom-web-applications.md/"}
		) {
			id
			html
			frontmatter {
				title
				featured_image {
					alt
					image {
						childImageSharp {
							gatsbyImageData(
								placeholder: BLURRED
							)
						}
					}
				}
				description
				introduction_title
				sub_services
				need_new_site {
					heading
					points_left {
						point {
							description
						}
					}
					points_right {
						point {
							description
						}
					}
				}
				how_it_works {
					description
					process_list {
						process {
							image {
								publicURL
							}
							description
							name
              alt
						}
					}
				}
				related_case_studies
			}
		}
    awardpageData: allMarkdownRemark(
      filter: { fileAbsolutePath: {regex: "/services/custom-web-applications.md/"} }
    ) {
      edges {
        node {
          frontmatter {
            seo_metadata {
              seo_title
              seo_keyword
              seo_description
              image_format
              feature_image_height
              img_width
              facebook_url
              twitter_page_username
              twitter_image_alt_tag
              twitter_estimated_reading_lable
              twitter_estimated_reading_time
              page_publish_date
              page_last_modified_time
              seo_Image {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 1200)
                }
              }
              seo_Single_Image 
            }
            Schema {
              sitelinks_schema
              breadcrumb_schema
              logo_schema
              organization_schema
              aggregate_rating_schema
              local_business_schema
              site_navigation_schema
              speakable_specification_schema
            }
            Hreflang {
              hreflang_1
              hreflang_2
              hreflang_3
              hreflang_4
            }
          }
        }
      }
    }
	}
`;
